// ログイン画面のJavaScript、CSS

import { getCsrfHeader, okOrReject } from '../lib/login_common';

import '../styles/login.css';

if(process.env.NODE_ENV === 'dev_eb') {
  import(/* webpackMode: 'eager' */ '../styles/environments/logo-development.css');
  import(/* webpackMode: 'eager' */ '../dev-images/images.defs.json');
} else {
  import(/* webpackMode: 'eager' */ '../styles/environments/logo-local-prod.css');
  import(/* webpackMode: 'eager' */ '../local-prod-images/images.defs.json');
}

// ログイン画面のトークン整合用

// ブラウザバック時のキャッシュを無効化、リロード
// eslint-disable-next-line @typescript-eslint/no-empty-function
onunload = onbeforeunload = () => {};

addEventListener('pageshow', (event) => {
  if ((event as PageTransitionEvent).persisted) {
    location.reload();
  }
});

const checkReload = () => {
  fetch('/api/login_token', {method: 'PUT', headers: getCsrfHeader()})
    .then(okOrReject)
    .catch(() => location.reload());
};

document.addEventListener('DOMContentLoaded', checkReload, false);

// 定期的にリロード
setInterval(checkReload, 1000 * 60);
